import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";
import getSrc from '@utils/get-root'
import getSchemas from '@utils/get-schemas'

const getImageSrc = (image, root) => (image && image.url) ? getSrc(image.url, root) : null

const getLinks = ({ icon }) => {
  const links = []
  if (icon) {
    links.push({
      rel: 'icon',
      href: icon
    })
  }
  return links
}

const Seo = ({ seo, lang, meta, image: metaImage }) => {
    const { site, favicon } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
                author
                keywords
                siteUrl
              }
            }
            favicon: sanityContenu(slug: {current: {eq: "favicon"}}) {
              cover {
                asset {
                  fixed(width: 32) {
                      src
                  }
                }
              }
            }
          }
        `
      )
    
    const image = getImageSrc(seo.image, site.siteMetadata.siteUrl)
    const keywords = seo.keywords.length > 0 ? seo.keywords : site.siteMetadata.keywords

    const links = getLinks({
      icon: favicon.cover.asset.fixed.src
    })

    const schemaOrgJSONLD = getSchemas(seo)

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={seo.title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            link={links}
            meta={[
                {
                    name: `description`,
                    content: seo.description,
                },
                {
                    name: "keywords",
                    content: keywords.join(","),
                },
                {
                    property: `og:title`,
                    content: seo.title,
                },
                {
                    property: `og:description`,
                    content: seo.description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: seo.title,
                },
                {
                    name: `twitter:description`,
                    content: seo.description,
                }
            ]        
            .concat(
                metaImage
                  ? [
                      {
                        property: "og:image",
                        content: image,
                      },
                      {
                        property: "og:image:width",
                        content: metaImage.metadata.dimensions.width,
                      },
                      {
                        property: "og:image:height",
                        content: metaImage.metadata.dimensions.height,
                      },
                      {
                        name: "twitter:card",
                        content: "summary_large_image",
                      },
                    ]
                  : [
                      {
                        name: "twitter:card",
                        content: "summary",
                      },
                    ]
              )
              .concat(meta)}
        >
          {schemaOrgJSONLD && <script type="application/ld+json">
            {JSON.stringify(schemaOrgJSONLD)}
          </script>}
        </Helmet>
      )
}

Seo.defaultProps = {
    lang: `fr`,
    meta: [],
    description: ``,
}

export default Seo