import React from "react"
import styled from "styled-components"
import HoriFooterMenu from "./HoriFooterMenu"
import HoriFooterSocial from "./HoriFooterSocial"
import Img from 'gatsby-image'

const Container = styled.section`
  background-color: ${({ theme }) => theme.footer.background};
  padding: calc(${({ theme }) => theme.paddingBox}*2) 0;
  color: ${({ theme }) => theme.footer.color};
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: ${({ theme }) => `calc(${theme.paddingBox}*2) ${theme.paddingBox}`};
  }
`

const StyledImage = styled.div`
  width: 150px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
    max-width: ${({ theme }) => theme.breakpoints.desktop};
    margin: auto;
    align-items: flex-start;
  }
`

const Footer =({ socials, cover, menu, email }) => (
  <Container>
    <Wrapper>
      <StyledImage>
        <Img fixed={cover} />
      </StyledImage>
      <HoriFooterMenu email={email} {...menu} />
      <HoriFooterSocial socials={socials} />
    </Wrapper>
  </Container>
)

export default Footer
