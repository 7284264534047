import defaultTheme from "@lib/simba/theme"
import merge from "lodash.merge"

const overrides = {
  backgroundLight: "#ffffff",
  backgroundDark: "#F6F6F6",
  colorText: "#076689",
  colorLink: "#0D266F",
  colorLinkHover: "rgba(86, 120, 138, 0.7)",
  header: {
    colorText: "#ffffff",
    background: "rgba(7, 102, 137, 0.75)",
  },
  menu: {
    colorLink: "#0D266F",
    colorLinkHover: "rgba(86, 120, 138, 0.7)",
    colorHamburger: "#FFF",
    background: "#286387"
  },
  form: {
    colorText: "#B2B2B2",
    colorTextLight: "#ffffff",
    colorInput: "#F5F5F5",
    colorButton: "#286387",
    colorButtonHover: "#439BD1",
  },
  footer: {
    color: "#6A6A6A",
    background: "#F6F6F6",
  },
  triptyque: {
    background: "#FCFBFA",
  },
  citation: {
    background: "#EFEFEF",
  },
}

export default merge(defaultTheme.theme, overrides)
