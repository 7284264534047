import React from "react"
import styled from "styled-components"
import { Link } from 'gatsby'
import Obfuscate from 'react-obfuscate'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 0 ${({ theme }) => theme.paddingBox};
    text-align: left;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Title = styled.span`
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 3vw;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 0.85vw;
  }
  a {
    color: ${({ theme }) => theme.colorText};
  }
`

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.colorText};

  &:hover {
    text-decoration: underline;
  }
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`

export default ({ name, links, email }) => (
  <Container>
    <Wrapper>
      <Title><Obfuscate email={email} /></Title>
      <LinkContainer>
        {links.map(({ title, slug: { current } }) => <StyledLink key={current} to={`/${current}`}>{title}</StyledLink>)}
      </LinkContainer>
    </Wrapper>
  </Container>
)
