import React from "react"
import styled from "styled-components"
import { SocialIcon } from 'react-social-icons'

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  > :not(:last-child) {
    margin-right: 6px;
  }
  margin-top: ${({ theme }) => theme.paddingBox};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    justify-content: flex-end;
    margin-top: 0;
  }
`


export default ({ socials }) => (
  <Container>
    {socials.map(social => (
      <SocialIcon key={social.url} url={social.url} />
    ))}
  </Container>
)
