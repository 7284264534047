import { rhythm, scale } from "@utils/typography"

const defaultTheme = {
  theme: {
    maxWidth: "1180px",
    paddingBox: rhythm(1),
    backgroundLight: "#F4EAD5",
    colorText: "#000000",
    colorLink: "#000000",
    colorLinkHover: "#E94E77",
    colorBorder: "#e3e3e3",
    backgroundLogo: "#fff",
    fontSizeTiny: scale(-1 / 2).fontSize,
    breakpoints: {
      mobile: "320px",
      tablet: "481px",
      desktop: "1180px",
    },
    article: {
      color: "#333",
      title: {
        color: "#fff",
        fontSize: scale(8 / 5).fontSize,
        innerFontSize: scale(1).fontSize,
      },
      listing: {
        fontSize: scale(2 / 5).fontSize,
      },
      navbar: {
        fontSize: scale(-1 / 4).fontSize,
      },
    },
    header: {
      background: "rgba(255, 255, 255, 0.5)",
      justify: "flex-start",
      colorText: "#000000",
    },
    menu: {
      background: "rgba(255, 255, 255, 0.5)",
      colorLink: "#000000",
      colorLinkHover: "#E94E77",
    },
    triptyque: {
      background: "transparent",
    },
    citation: {
      fontSize: scale(5 / 5).fontSize,
      fontSizeAuthor: scale(3 / 5).fontSize,
      fontSizeGoogle: scale(-1 / 2).fontSize,
      background: "transparent",
    },
    footer: {
      background: "#1A1A1A",
      color: "#F4EAD5",
      colorLink: "#F4EAD5",
      accroche: {
        fontSize: scale(3 / 5).fontSize,
      },
      mention: {
        fontSize: scale(1 / 50).fontSize,
      },
    },
  },
}

export default defaultTheme
