import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import RoundedLogo from '@lib/simba/rounded-logo'

const Container = styled.div`    
    background: ${({ theme }) => theme.menu.background};
    padding: 0 ${({ theme }) => theme.paddingBox};    
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: flex;
        justify-content: ${({ front }) => front ? 'center' : 'space-between'};
        align-items: center;
        width: 100%;
        z-index: 9000;
        position: fixed;
        top: 0;
        flex-direction: row;
    }
`

const LogoContainer = styled.div`
    height: 0;
    padding: ${({ theme }) => `calc(${theme.paddingBox} / 4) ${theme.paddingBox}`};
`

const Element = ({ links, front, name, logo }) => (
    <Container front={front}>
        {!front ? (
            <Link to="/"><LogoContainer><RoundedLogo img={logo} alt={name}/></LogoContainer></Link>
        ) : null}
    </Container>
)

Element.defaultProps = {
    links: [],
    front: false
}

Element.propTypes = {
    links: PropTypes.array,
    front: PropTypes.bool
}

export default Element
