import React from 'react';
import Header from '@lib/simba/header';
import { useStaticQuery, graphql } from 'gatsby';
import createLink from '@utils/create-link'

export default ({ front }) => {
    const data = useStaticQuery(graphql`
query HeaderQuery {
    menu: sanityMenu(slug: {current: {eq: "principal"}}) {
        links {
            __typename
            ... on SanityCategory {
                id
                title
                slug {
                    current
                }
            }
            ... on SanityContenu {
                id
                title
                slug {
                    current
                }
            }
        }
    }
    home: sanityContenu(
        order: {eq: 1}
        categories: {elemMatch: {slug: {current: {eq: "accueil"}}}}
    ) {
        id
        title
        resume
        cover {
            asset {
                fluid(maxWidth: 1280) {
                    ...GatsbySanityImageFluid
                }
            }
        }
    }
    sanityIdentity {
        name
        logo {
            asset {
                fixed(width: 100) {
                    ...GatsbySanityImageFixed
                }
            }
        }
        accroche
        phone
        mobile
        email
        social {
            name
            url
        }
    }
}
`)

    return (
    <Header
        data={data.home}
        links={data.menu.links.filter(link => link.slug !== null).map(({ __typename, slug, title }) => ({
            to: createLink(__typename, slug.current),
            title,
            name: title
        }))}
        name={data.sanityIdentity.name}
        logo={data.sanityIdentity.logo.asset.fixed}
        front={front}
    />)
}