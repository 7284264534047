import React from 'react'
import styled from 'styled-components'
import { Header } from '@lib/simba/_statics'
import BackgroundImage from 'gatsby-background-image'
import { Parallax } from 'react-scroll-parallax'

const HeaderArticle = styled.div`
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.paddingBox};
`

const Title = styled.h1`
    color: ${({ theme }) => theme.article.title.color};
    font-size: ${({ theme }) => theme.article.title.fontSize};
    text-align: center;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        margin: ${({ theme }) => theme.paddingBox} auto 0;
        max-width: ${({ theme }) => theme.maxWidth};
    }
`

const StyledBackground = styled(BackgroundImage)`
  &::before,
  &::after {
    filter: brightness(0.5);
  }
`

export default ({ img, title }) => (
    <React.Fragment>
        <Header front={false} />
        <StyledBackground
            fluid={img}
        >
            <HeaderArticle>
                <Parallax y={[-150, 50]}>
                    <Title>{title}</Title>
                </Parallax>
            </HeaderArticle>
        </StyledBackground>
    </React.Fragment>
)