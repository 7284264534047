import React from 'react'
import styled from 'styled-components'

const Word = styled.span`
    font-weight: ${({ weight }) => weight < 1 ? 'normal' : (weight < 3 ? 'bold' : 'bolder')};
`

export default ({ s }) => (
    <span>
        {s.split(' ').map((w, index) => <Word key={index} weight={index}>{w} </Word>)}
    </span>
)