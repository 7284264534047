import React from 'react'
import styled from 'styled-components'
import Accroche from './Accroche'
import Social from './Social'
import { Link } from 'gatsby'
import createLink from '@utils/create-link'

const Container = styled.section`
    background-color: ${({ theme }) => theme.footer.background};
    padding: ${({ theme }) => theme.paddingBox};
    color: ${({ theme }) => theme.footer.color};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        padding: calc(${({ theme }) => theme.paddingBox} * 2) ${({ theme }) => theme.paddingBox};
    }
`

const InnerContainer = styled.div`
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        max-width: ${({ theme }) => theme.maxWidth};
        width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: 3fr 1fr;
    }
`

const Infos = styled.div``
const ContactBtnContainer = styled.div``
const ContactBtn = styled.div`
    display: inline-block;
    border: 4px solid ${({ theme }) => theme.footer.color};
    position: relative;
    a {
        color: ${({ theme }) => theme.footer.color};
        display: block;
        background: ${({ theme }) => theme.footer.background};
        padding: ${({ theme }) => `calc(${theme.paddingBox} / 4) calc(${theme.paddingBox} / 2)`};
        margin: 0 ${({ theme }) => `calc(${theme.paddingBox} / 2)`};
        position: relative;
        transition: all .2s ease-in;
    }
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background: ${({ theme }) => theme.footer.color};
        top: 50%;
        margin-top: -2px;
    }
    &:hover {
        a {
            padding: ${({ theme }) => `calc(${theme.paddingBox} / 4) calc(${theme.paddingBox} / 4)`};
            margin: 0 ${({ theme }) => `calc(${theme.paddingBox} * 3 / 4)`};
        }
    }
`
const BottomInfosContainer = styled.div`
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        max-width: ${({ theme }) => theme.maxWidth};
        width: 100%;
        margin: calc(${({ theme }) => theme.paddingBox} * 2) auto 0;
        font-size: ${({ theme }) => theme.footer.mention.fontSize};
    }
`

const createMenuBottom = data => {
    return {
        social: data.links.filter(link => link.slug !== null).map(({ __typename, slug, title }) => ({
            url: createLink(__typename, slug.current),
            title,
            name: title
        })),
        mobile: null,
        phone: null
    }
}

export default ({ data, contact, menu }) => {
    
    return (
    <Container>
        <InnerContainer>
            <Infos>
                <Accroche accroche={data.accroche} />
                <Social data={data} />
            </Infos>
            <ContactBtnContainer>
                <ContactBtn>
                    <Link to={`/${contact.slug.current}`}>{contact.title}</Link>
                </ContactBtn>
            </ContactBtnContainer>            
        </InnerContainer>
        <BottomInfosContainer>
            <div>{data.mentions}</div>
            {menu && <Social data={createMenuBottom(menu)} />}
        </BottomInfosContainer>
    </Container>
)}