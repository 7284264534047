import React from 'react';
import { HoriFooter } from '@lib/simba/footer';
import { useStaticQuery, graphql } from 'gatsby';

export default () => {
    const data = useStaticQuery(graphql`
    query FooterQuery {
        sanityIdentity {
            name
            logo {
                asset {
                    fixed(width: 150) {
                        ...GatsbySanityImageFixed
                    }
                }
            }
            accroche
            phone
            mobile
            email
            social {
                name
                url
            }
            mentions
        }
        menu: sanityMenu(slug: {current: {eq: "footer"}}) {
            name
            links {
                __typename
                ...on SanityContenu {
                    title
                    slug {
                        current
                    }
                }
                ...on SanityCategory {
                    title
                    slug {
                        current
                    }
                }
            }
        }
    }`)

    return (
        <HoriFooter email={data.sanityIdentity.email} socials={data.sanityIdentity.social} cover={data.sanityIdentity.logo.asset.fixed} menu={data.menu} />
    )
}