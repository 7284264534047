import React from "react"
import styled from "styled-components"
import { DivboxCenter } from "@lib/simba/divbox"
import Pulse from "react-reveal/Pulse"

const Container = styled.section`
  padding: ${({ theme }) => theme.paddingBox};
  margin: calc(${({ theme }) => theme.paddingBox} / 2);
  background: ${({ theme }) => theme.header.background};
  color: ${({ theme }) => theme.header.colorText};
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: ${({ theme }) => theme.maxWidth};
    margin: 0 ${({ theme }) => theme.paddingBox};
  }
`

const Title = styled.h1`
  margin: ${({ theme }) => theme.paddingBox};
  padding: 0;
  text-align: center;
`

const Resume = styled.div`
  text-align: center;
  max-width: 100%;
  white-space: pre-wrap;
  line-height: 1.5;
  font-size: 4vw;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1vw;
  }
`

export default ({ resume, title }) =>
  resume !== undefined || title !== undefined ? (
    <DivboxCenter>
      <Container>        
        {title ? (
          <Pulse>
            <Title>{title}</Title>
          </Pulse>
        ) : null}
        {resume ? <Resume>{resume}</Resume> : null}
      </Container>
    </DivboxCenter>
  ) : null
