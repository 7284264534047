import React from "react"
import styled from "styled-components"
import MenuOne from "@lib/simba/menu-one"
import RoundedLogo from "@lib/simba/rounded-logo"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"
import Divbox, { DivboxCenter } from "@lib/simba/divbox"
import Presentation from "./Presentation"
import { Parallax } from "react-scroll-parallax"

const HeaderSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(${({ theme }) => theme.paddingBox} * 4);
  height: 50vh;
`

const Upper = styled.div`
  margin-top: calc(-50vh + 150px + ${({ theme }) => theme.paddingBox} * 6);
`

const Header = ({ links, name, logo, data, front, shape }) => {
  return front ? (
    <Divbox>
      {shape && <MenuOne alt={name} img={logo} links={links} front={front} />}
      <BackgroundImage Tag="section" fluid={data.cover.asset.fluid}>
        <HeaderSection>
          <Parallax y={[-100, 20]}>
            <DivboxCenter>
              <RoundedLogo alt={name} img={logo} />
            </DivboxCenter>
          </Parallax>          
        </HeaderSection>
      </BackgroundImage>
      <Upper>
        <Parallax y={[20, -20]}>
          <Divbox>
            <Presentation {...data} />
          </Divbox>
        </Parallax>
      </Upper>
    </Divbox>
  ) : (
    <MenuOne alt={name} logo={logo} links={links} front={front} />
  )
}

Header.defaultProps = {
  links: [],
}

Header.propTypes = {
  links: PropTypes.array,
  name: PropTypes.string,
}

export default Header
